<template>
    <div class="like-project-box">
        <CompHeader title="我看好的创意">
            <template v-slot:tips v-if="total">共{{total}}个项目</template>
        </CompHeader>
        <template v-if="isLoad">
            <template v-if="prolist.length">
                <CompProList :list="prolist" />
                <CompPagination :total="total" :pageSize="page_size" @change="pageChange"/>
            </template>
            <CompError
                v-else
                class="cont-nothing-box"
                message="暂无看好的创意"
                />
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex"

import CompHeader from "@@/common/header.vue"
import CompProList from "@@/user/proList.vue"
import CompPagination from "@@/common/pagination.vue"
import CompError from "@@/common/error.vue"

import { bullProductList } from "@api/index"
export default {
    components: {
        CompHeader,
        CompProList,
        CompPagination,
        CompError
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId
        }),
    },
    data() {
        return {
            isLoad: false,
            prolist: [],
            page: 1,
            page_size: 9,
            total: 0
        }
    },
    async mounted() {
        await this.getList()
        this.isLoad = true
    }, 
    methods: {
        async getList() {
            const { status, data } = await bullProductList({
                to_user_id: this.userId,
                page: this.page,
                page_size: this.page_size,
            })
            if (status == 0) {
                this.prolist = data.list
                this.total = data.total
            }
        },
        pageChange(val) {
            this.page= val
            this.getList()
        }
    }
}
</script>
